import { RAG_STATUS } from 'helpers/calc-rag-status';
import mostRecentDate from 'helpers/most-recent-date';
import useCurriculumStore from '..';
import { REMEMBER_CONFIG } from '../../app-constants';

function getGoalsProgress(userState, userId) {
  return userId
    ? userState.pupilsGoalsProgress[userId]
    : userState.goalsProgress ?? {};
}

function getGoalProgress(userState, goalId, userId) {
  return getGoalsProgress(userState, userId)[goalId] ?? {};
}

function getStepProgress(userState, goalId, userId, step) {
  return getGoalProgress(userState, goalId, userId)[step] ?? {};
}

// Returns: 0 or 1
// Input: userState.goalsProgress[goalId]?.myGoal?.lastNav
function myGoalStepProgress(goalId, userId) {
  return (userState) => {
    const progress = getStepProgress(userState, goalId, userId, 'myGoal');
    return Number(!!progress.lastNav);
  };
}

// Input:
// curriculum
//    [prereqStatus]
function readinessStepProgress(goalId, userId) {
  return (userState) => {
    const state = useCurriculumStore.getState();
    const rags = (state.goals[goalId]?.readinessObjectives ?? [])
      .filter((o) => o.canQuiz)
      .map((o) => state.prereqStatus[o.id]);

    if (!rags.length) {
      const progress = getStepProgress(userState, goalId, userId, 'readiness');
      return progress.lastNav ? 1 : 0;
    }

    const maxPoints = rags.length;
    const points = rags.filter((rag) => rag === RAG_STATUS.GREEN).length;

    return points / maxPoints;
  };
}

function readinessQuizAttempted(goalId, type, userId) {
  return (userState) => {
    const progress = getStepProgress(userState, goalId, userId, 'readiness');
    return type === 'SPECIFIC_READINESS'
      ? progress.specificQuizAttempted
      : progress.generalQuizAttempted;
  };
}

function readinessQuizCompleted(goalId, type, userId) {
  return (userState) => {
    const progress = getStepProgress(userState, goalId, userId, 'readiness');
    return type === 'SPECIFIC_READINESS'
      ? progress.specificQuizCompleted
      : progress.generalQuizCompleted;
  };
}

function getGoalCompletedStatuses(goalIds) {
  return (userState) => {
    return goalIds.reduce((o, id) => {
      const curr = o || {};
      curr[id] = goalIsCompleted(id)(userState);
      return curr;
    }, {});
  };
}

// Returns: { playhead:Integer, progress:Number<Range[0, 1]>, watchedMins:Integer, durationMins:Integer }
// Input: userState.goalsProgress[goalId]?.[step]?.[videoId]
function videoProgress(goalId, step, videoId, userId) {
  return (userState) => {
    const goalsProgress = userId
      ? userState.pupilsGoalsProgress[userId]
      : userState.goalsProgress;
    const stepStatus = goalsProgress?.[goalId]?.[step];
    const video = stepStatus?.videos?.[videoId] ?? {};
    const { playhead, watchedPeriods, duration } = video;
    let { progress, watchedMins, durationMins } = video;

    if (!watchedPeriods) {
      return {
        playhead: 0,
        progress: 0,
      };
    }

    const watchedSecs = watchedPeriods.reduce(
      (sum, [start, end]) => sum + end - start,
      0
    );

    // Vimeo reporting of video durations not reliable/consistent.
    // Can end up with progress > 1.
    if (progress == null) {
      progress = Math.min(1, watchedSecs / duration);
    }

    // Avoid absurd visual state where it shows watched `n` of `n` mins, but incomplete.
    if (durationMins == null) {
      durationMins = Math.ceil(duration / 60);
    }

    if (watchedMins == null) {
      watchedMins = progress >= 1 ? durationMins : Math.floor(watchedSecs / 60);
    }

    return {
      playhead,
      progress,
      watchedMins,
      durationMins,
      watchedPeriods,
    };
  };
}

// Returns: Number<Range[0, 1]>
// Input:
//    curriculum
//    [videoProgress]
function videoStepProgress(goalId, step, userId) {
  return (userState) => {
    const videoIds =
      useCurriculumStore.getState().goals?.[goalId]?.[`${step}VideoIds`];

    if (!videoIds) return 0;

    return (
      videoIds.reduce(
        (sum, videoId) =>
          sum +
          videoProgress(goalId, step, videoId, userId)(userState).progress,
        0
      ) / videoIds.length
    );
  };
}

function getFirstIncompleteVideo(goalId, step, videoIds) {
  return (userState) =>
    videoIds.find(
      (videoId) =>
        videoProgress(goalId, step, videoId)(userState).progress !== 1
    );
}

// Returns: Number<Range[0, 1]>
// Input:
//    videoStepProgress
//    userState.goalsProgress[goalId]?.learn
function learnStepProgress(goalId, userId) {
  return (userState) => videoStepProgress(goalId, 'learn', userId)(userState);
}

// Returns: Number<Range[0, 1]>
// Input:
//    videoStepProgress
//    userState.goalsProgress[goalId]?.do
function doStepProgress(goalId, userId) {
  return (userState) => videoStepProgress(goalId, 'do', userId)(userState);
}

// Returns: Number<Range[0, 1]>
// Input:
// curriculum
//    [practice]
function practiceStepProgress(goalId, userId) {
  return (userState) => {
    const state = useCurriculumStore.getState();
    const practiceProgress = state.practice[goalId];

    if (!practiceProgress) return 0;

    const { totalCount, maxScore } = practiceProgress;

    if (totalCount === 0) return 0;
    if (maxScore === 5) return 1;
    return 0.5;
  };
}

// Returns: Number<Range[0, 1]>
// Input: userState.goalsProgress[goalId]?.quiz?.quizAttempts
function quizStepProgress(goalId, userId) {
  return (userState) => {
    const { numCompletedQuizzes = 0, topScore = 0 } = getStepProgress(
      userState,
      goalId,
      userId,
      'quiz'
    );

    if (numCompletedQuizzes === 0) return 0;

    return Math.min(1, topScore / 100);
  };
}

// Returns: Number<Range[0, 1]>
// Input: userState.goalsProgress[goalId]?.remember
function rememberStepProgress(goalId, userId) {
  return (userState) => {
    const { numCompletedQuizzes = 0, rememberMarksAchieved = 0 } =
      getStepProgress(userState, goalId, userId, 'remember');

    if (numCompletedQuizzes === 0) return 0;

    return Math.min(
      1,
      rememberMarksAchieved / REMEMBER_CONFIG.NUM_GOAL_QUESTIONS
    );
  };
}

// Returns: { quizInProgress, numCompletedQuizzes, lastScore, topScore } ** N.B. Scores given as % **
// Input: userState.goalsProgress[goalId]?.quiz?.quizAttempts
function quizStepState(goalId, step) {
  return (userState) => userState.goalsProgress?.[goalId]?.[step];
}

function goalIsNotReady(goalId, userId) {
  return (userState) => {
    const state = useCurriculumStore.getState();
    const readinessObjectives = state.goals[goalId]?.readinessObjectives ?? [];
    const readinessObjInFocusFive = readinessObjectives.filter(({ id }) =>
      state.focusFive.goalIds.includes(id)
    );

    return readinessObjInFocusFive.some(
      (obj) => !goalIsCompleted(obj.id)(userState)
    );
  };
}

// Returns: Boolean
// Input:
//    each step progress
//    userState.goalsProgress[goalId]
function goalIsStarted(goalId, userId) {
  return (userState) => {
    const goalProgress = getGoalProgress(userState, goalId, userId);
    if (!Object.keys(goalProgress).length) return false;
    return !!(
      myGoalStepProgress(goalId, userId)(userState) ||
      readinessStepProgress(goalId, userId)(userState) ||
      learnStepProgress(goalId, userId)(userState) ||
      doStepProgress(goalId, userId)(userState) ||
      quizStepProgress(goalId, userId)(userState) ||
      rememberStepProgress(goalId, userId)(userState)
    );
  };
}

function courseIsStarted(goalIds) {
  return (userState) => goalIds.some((id) => goalIsStarted(id)(userState));
}

function revisionIsStarted(goalIds) {
  return (userState) => goalIds.some((id) => goalIsStarted(id)(userState));
}

function courseIsCompleted(goalIds) {
  return (userState) => goalIds.every((id) => goalIsCompleted(id)(userState));
}

function revisionIsCompleted(goalIds) {
  return (userState) => goalIds.every((id) => goalIsCompleted(id)(userState));
}

// returns: percentage goals complete to max 2d.p.
function courseProgress(goalIds) {
  return (userState) => {
    const numGoalsCompleted = goalIds.filter((id) =>
      goalIsCompleted(id)(userState)
    ).length;

    const proportion = numGoalsCompleted / goalIds.length;
    const pcMax2dp = Math.round(10_000 * proportion) / 100;
    return pcMax2dp;
  };
}

// returns: percentage goals complete to max 2d.p.
function revisionProgress(goalIds) {
  return (userState) => {
    const numGoalsCompleted = goalIds.filter((id) =>
      goalIsCompleted(id)(userState)
    ).length;

    const proportion = numGoalsCompleted / goalIds.length;
    const pcMax2dp = Math.round(10_000 * proportion) / 100;
    return pcMax2dp;
  };
}

// Returns: Boolean
// Input:
//    each step progress
//    userState.goalsProgress[goalId]
function goalIsCompleted(goalId, userId) {
  return (userState) => {
    // If we already have a completion date the goal must be completed.
    // We probably don't even need the subsequent code that checks the
    // individual step progreess any more?
    if (userState.completedGoals?.[goalId]) return true;

    const quizProgress = getStepProgress(userState, goalId, userId, 'quiz');

    if (quizProgress.topScore === 100) return true;
    if (myGoalStepProgress(goalId, userId)(userState) !== 1) return false;
    if (learnStepProgress(goalId, userId)(userState) !== 1) return false;
    if (doStepProgress(goalId, userId)(userState) !== 1) return false;
    return quizStepProgress(goalId, userId)(userState) === 1;
  };
}

function stepIsComplete(goalId, step) {
  return (userState) => {
    switch (step) {
      case 'myGoal':
        return myGoalStepProgress(goalId)(userState) === 1;
      case 'readiness':
        return readinessStepProgress(goalId)(userState) === 1;
      case 'learn':
        return learnStepProgress(goalId)(userState) === 1;
      case 'do':
        return doStepProgress(goalId)(userState) === 1;
      case 'practice':
        return practiceStepProgress(goalId)(userState) === 1;
      case 'quiz':
        return quizStepProgress(goalId)(userState) === 1;
      case 'remember':
        return rememberStepProgress(goalId)(userState) === 1;
      default:
        return false;
    }
  };
}

function stepIsStarted(goalId, step) {
  return (userState) => {
    switch (step) {
      case 'myGoal':
        return myGoalStepProgress(goalId)(userState) > 0;
      case 'readiness':
        return readinessStepProgress(goalId)(userState) > 0;
      case 'learn':
        return learnStepProgress(goalId)(userState) > 0;
      case 'do':
        return doStepProgress(goalId)(userState) > 0;
      case 'quiz':
        return quizStepProgress(goalId)(userState) > 0;
      case 'remember':
        return rememberStepProgress(goalId)(userState) > 0;
      default:
        return false;
    }
  };
}

function stepProgress(goalId, step) {
  return (userState) => {
    switch (step) {
      case 'myGoal':
        return myGoalStepProgress(goalId)(userState);
      case 'readiness':
        return readinessStepProgress(goalId)(userState);
      case 'learn':
        return learnStepProgress(goalId)(userState);
      case 'do':
        return doStepProgress(goalId)(userState);
      case 'practice':
        return practiceStepProgress(goalId)(userState);
      case 'quiz':
        return quizStepProgress(goalId)(userState);
      case 'remember':
        return rememberStepProgress(goalId)(userState);
      default:
        return false;
    }
  };
}

function goalStepsCompleted(goalId, hasGoal) {
  return (userState) => {
    if (!hasGoal) return null;

    const steps = ['readiness', 'learn', 'do', 'practice', 'quiz'];

    const hasRememberStep =
      useCurriculumStore.getState().goals[goalId]?.canRemember;
    const hasReadinessStep = useCurriculumStore
      .getState()
      .shouldShowReadiness(goalId);

    if (hasRememberStep) steps.push('remember');
    if (!hasReadinessStep) {
      const ix = steps.indexOf('readiness');
      if (ix !== -1) steps.splice(ix, 1);
    }

    const result = Object.fromEntries(
      steps.map((step) => [step, stepIsComplete(goalId, step)(userState)])
    );

    return result;
  };
}

function goalStepsProgress(goalId, hasGoal) {
  return (userState) => {
    if (!hasGoal) return null;

    const steps = ['readiness', 'learn', 'do', 'practice', 'quiz'];

    const hasRememberStep =
      useCurriculumStore.getState().goals[goalId]?.canRemember;
    const hasReadinessStep = useCurriculumStore
      .getState()
      .shouldShowReadiness(goalId);

    if (hasRememberStep) steps.push('remember');
    if (!hasReadinessStep) {
      const ix = steps.indexOf('readiness');
      if (ix !== -1) steps.splice(ix, 1);
    }

    const result = Object.fromEntries(
      steps.map((step) => [step, stepProgress(goalId, step)(userState)])
    );

    return result;
  };
}

// Returns: one of ['not_started', 'not_ready', 'in_progress', 'completed']
// Input:
//    goalIsCompleted
//    goalIsStarted
//    goalIsReady
//    userState.goalsProgress[goalId]
function goalStatus(goalId, userId) {
  return (userState) => {
    switch (true) {
      case goalIsCompleted(goalId, userId)(userState):
        return 'completed';
      case goalIsNotReady(goalId, userId)(userState):
        return 'not_ready';
      case goalIsStarted(goalId, userId)(userState):
        return 'in_progress';
      default:
        return 'not_started';
    }
  };
}

function revisionStatus(revisionId, userId) {
  const { revisionGoals = [] } =
    useCurriculumStore.getState().getRevision(revisionId) ?? {};
  return (userState) => {
    switch (true) {
      case revisionIsCompleted(
        revisionGoals.map((goal) => goal.goalId),
        userId
      )(userState):
        return 'completed';
      case revisionIsStarted(
        revisionGoals.map((goal) => goal.goalId),
        userId
      )(userState):
        return 'in_progress';
      default:
        return 'not_started';
    }
  };
}

function goalStatuses(userId) {
  return (userState) => {
    const statuses = {};
    const goalIds = Object.keys(
      userId
        ? userState.pupilsGoalsProgress[userId]
        : userState.goalsProgress || {}
    );
    for (const goalId of goalIds) {
      const status = goalStatus(goalId, userId)(userState);
      statuses[goalId] = status;
    }
    return statuses;
  };
}

function getGoalStatusesDict(goalIds) {
  return (userState) => {
    return Object.fromEntries(
      goalIds.map((id) => [id, goalStatus(id)(userState)])
    );
  };
}

function pupilsEncounteredGoals(pupils) {
  return (userState) => {
    let encountered = {};
    pupils.forEach((pupil) => {
      const pupilEncountered = findEncounteredGoals(pupil.id)(userState);
      encountered = { ...encountered, ...pupilEncountered };
    });
    return encountered;
  };
}

function findEncounteredGoals(userId) {
  return (userState) => {
    const encountered = {};
    const goalsProgress = userId
      ? userState.pupilsGoalsProgress[userId]
      : userState.goalsProgress;
    const { diagnosticProgress } = userState;
    const { diagnosticQuizAttempt } = useCurriculumStore.getState();

    if (goalsProgress) {
      // First add any goals encountered in readiness and remember quizzes
      Object.values(goalsProgress).forEach((goal) => {
        [goal.quiz, goal.readiness, goal.remember].forEach((quiz) => {
          const { quizAttempts } = quiz;
          if (quizAttempts) {
            Object.values(quizAttempts).forEach((quizAttempt) => {
              quizAttempt.answers?.forEach((answer) => {
                encountered[answer.goalId] = {
                  goalId: answer.goalId,
                  status: 'encountered',
                  date: mostRecentDate([
                    quiz.lastNav,
                    encountered[answer.goalId]?.date,
                  ]),
                };
              });
            });
          }
        });
      });
    }

    if (diagnosticProgress && diagnosticProgress.quizAttempts) {
      // Now add any goals encountered in diagnostic quizzes
      Object.values(diagnosticProgress.quizAttempts).forEach((quizAttempt) => {
        quizAttempt.answers?.forEach((answer) => {
          encountered[answer.goalId] = {
            goalId: answer.goalId,
            status: 'encountered',
            date: mostRecentDate([
              quizAttempt.creationDate,
              encountered[answer.goalId]?.date,
            ]),
          };
        });
      });
    }

    if (diagnosticQuizAttempt) {
      diagnosticQuizAttempt.answers?.forEach((answer) => {
        encountered[answer.goalId] = {
          goalId: answer.goalId,
          status: 'encountered',
          date: mostRecentDate([
            diagnosticQuizAttempt.creationDate,
            encountered[answer.goalId]?.date,
          ]),
        };
      });
    }

    if (goalsProgress) {
      // Finally add the root goals from goalsProgress
      Object.values(goalsProgress).forEach((goal) => {
        encountered[goal.goalId] = {
          goalId: goal.goalId,
          status: goalStatus(goal.goalId, userId)(userState),
          date: mostRecentDate([
            goal.do.lastNav,
            goal.learn.lastNav,
            goal.myGoal.lastNav,
            encountered[goal.goalId]?.date,
          ]),
        };
      });
    }

    return encountered;
  };
}

// Returns: Boolean
// Input:
//    curriculum
//    [goalIsCompleted]
function ideaIsCompleted(ideaId) {
  return (userState) => {
    const { goalIds } = useCurriculumStore.getState().ideas[ideaId];
    return goalIds.every((id) => goalIsCompleted(id)(userState));
  };
}

// Returns: one of ['not_started', 'in_progress', 'completed']
// Input:
//    ideaIsCompleted
//    [goalIsStarted]
function ideaStatus(ideaId) {
  return (userState) => {
    if (ideaIsCompleted(ideaId)(userState)) return 'completed';

    const { goalIds } = useCurriculumStore.getState().ideas[ideaId];
    return goalIds.some((id) => goalIsStarted(id)(userState))
      ? 'in_progress'
      : 'not_started';
  };
}

function elementIsCompleted(elementType, elementId) {
  return (userState) => {
    switch (elementType) {
      case 'idea':
        return ideaIsCompleted(elementId)(userState);
      case 'goal':
        return goalIsCompleted(elementId)(userState);
      default:
        throw new Error('Unexpected elementType');
    }
  };
}

function elementStatus(elementType, elementId) {
  return (userState) => {
    switch (elementType) {
      case 'idea':
        return ideaStatus(elementId)(userState);
      case 'goal':
        return goalStatus(elementId)(userState);
      case 'revision':
        return revisionStatus(elementId)(userState);
      default:
        throw new Error('Unexpected elementType');
    }
  };
}

export {
  elementIsCompleted,
  elementStatus,
  goalStepsCompleted,
  goalStepsProgress,
  getGoalCompletedStatuses,
  courseProgress,
  revisionProgress,
  courseIsStarted,
  revisionIsStarted,
  courseIsCompleted,
  revisionIsCompleted,
  readinessStepProgress,
  quizStepState,
  readinessQuizAttempted,
  readinessQuizCompleted,
  videoProgress,
  getFirstIncompleteVideo,
  stepIsComplete,
  stepIsStarted,
  rememberStepProgress,
  goalStatuses,
  getGoalStatusesDict,
  pupilsEncounteredGoals,
};

import {
  onBespokeDeleteSuccess,
  onBespokeUpdateSuccess,
  onToggleCourseIdeaOrGoalSuccess,
  onUnenrolPupilsSuccess,
  onEnrolPupilsSuccess,
  onEnrolSuccess,
  onUnenrolSuccess,
} from './courses';
import {
  onPupilTaggingSuccess,
  onPupilUntaggingSuccess,
} from './pupil-tag-update';
import { onTempPasswordUpdateSuccess } from './temp-password';
import {
  onIssueInvitationsSucceeded,
  onWithdrawInvitationSucceeded,
  onInvitationStatusUpdated,
  onInvitationWithdrawn,
  onInvitationIssued,
} from './invitations';
import { onGoalCompleted } from './goal-completed';
import {
  onLinkLearner,
  onUnlinkLearner,
  onFamilyNameUpdate,
  onFamilySubscriptionUpdated,
  onFamilyPupilSubscriptionsUpdated,
  onFamilyAddPaymentMethod,
  onAddPendingChildren,
} from './families';
import {
  onGetPupilsProgressSuccess,
  onGetPupilsProgressSuccessUser,
  onGetPupilsPracticeResultsSuccess,
} from './pupils-progress';
import { onDiagnosticProgressReset } from './diagnostics';
import {
  onFocusFiveGetSuccess,
  onFocusFiveGetGoalsSuccess,
  onFocusFiveUpdateSuccess,
  onGetTutorialQuestionsSuccess,
  onGetMathsQuestionSuccess,
  onAnswerTutorialQuestionSuccess,
  onAnswerDiagnosticQuestionSuccess,
  onGetDiagnosticRoundSuccess,
  onResetFocusFiveDiagnosticsSuccess,
} from './focus-five';
import { onProgressUpdated } from './progress-updated';
import { onSetGoalsTarget } from './goals-target';
import { onMemoryBoostQuizCompleted } from './memory-boost';
import { onAnswerPraticeQuestionSuccess } from './practice';
import { onRevisionUpdated } from './revision';

// Entries are of the form:
// ACTION_NAME: handlerFunction
// or
// ACTION_NAME: [handlerFunction1, handlerFunction2, ...]
export const socketHandlers = {
  ADD_PENDING_CHILDREN_SUCCEEDED: onAddPendingChildren,
  FAMILY_ADD_PAYMENT_METHOD_SUCCEEDED: onFamilyAddPaymentMethod,
  FAMILY_SUBSCRIPTION_UPDATED: onFamilySubscriptionUpdated,
  FAMILY_PUPIL_SUBSCRIPTIONS_UPDATED: onFamilyPupilSubscriptionsUpdated,
  DELETE_BESPOKE_COURSE_SUCCESS: onBespokeDeleteSuccess,
  UPDATE_BESPOKE_COURSE_SUCCESS: onBespokeUpdateSuccess,
  DIAGNOSTIC_PROGRESS_RESET_SUCCESS: onDiagnosticProgressReset,
  ENROL_PUPILS_SUCCESS: onEnrolPupilsSuccess,
  ENROL_SUCCESS: onEnrolSuccess,
  UNENROL_PUPILS_SUCCESS: onUnenrolPupilsSuccess,
  UNENROL_SUCCESS: onUnenrolSuccess,
  INVITATION_ISSUED: onInvitationIssued,
  INVITATION_STATUS_UPDATED: onInvitationStatusUpdated,
  INVITATION_WITHDRAWN: onInvitationWithdrawn,
  ISSUE_INVITATIONS_SUCCEEDED: onIssueInvitationsSucceeded,
  WITHDRAW_INVITATION_SUCCEEDED: onWithdrawInvitationSucceeded,
  LINK_LEARNER_SUCCEEDED: onLinkLearner,
  UNLINK_LEARNER_SUCCEEDED: onUnlinkLearner,
  TAGGING_SUCCESS: onPupilTaggingSuccess,
  UNTAGGING_SUCCESS: onPupilUntaggingSuccess,
  SET_TEMP_PASSWORD_SUCCESS: onTempPasswordUpdateSuccess,
  UNSET_TEMP_PASSWORD_SUCCESS: onTempPasswordUpdateSuccess,
  GET_PUPILS_PROGRESS_SUCCESS: onGetPupilsProgressSuccess,
  GOAL_COMPLETED: onGoalCompleted,
  PROGRESS_UPDATED: onProgressUpdated,
  SET_GOALS_TARGET_SUCCESS: onSetGoalsTarget,
  TOGGLE_COURSE_IDEA_OR_GOAL_SUCCESS: onToggleCourseIdeaOrGoalSuccess,
  UPDATE_FAMILY_NAME_SUCCEEDED: onFamilyNameUpdate,
  MEMORY_BOOST_QUIZ_COMPLETED: onMemoryBoostQuizCompleted,
  UPDATE_FOCUS_FIVE_GOALS_SUCCEEDED: onFocusFiveUpdateSuccess,
  GET_FOCUS_FIVE_GOALS_SUCCEEDED: onFocusFiveGetGoalsSuccess,
  GET_FOCUS_FIVE_SUCCEEDED: onFocusFiveGetSuccess,
  GET_TUTORIAL_QUESTIONS_SUCCEEDED: onGetTutorialQuestionsSuccess,
  GET_MATHS_QUESTION_SUCCEEDED: onGetMathsQuestionSuccess,
  ANSWER_TUTORIAL_QUESTION_SUCCEEDED: onAnswerTutorialQuestionSuccess,
  ANSWER_DIAGNOSTIC_QUESTION_SUCCEEDED: onAnswerDiagnosticQuestionSuccess,
  GET_DIAGNOSTIC_ROUND_SUCCEEDED: onGetDiagnosticRoundSuccess,
  RESET_FOCUS_FIVE_DIAGNOSTICS_SUCCEEDED: onResetFocusFiveDiagnosticsSuccess,
  ANSWER_DYNAMIC_QUESTION_SUCCEEDED: onAnswerPraticeQuestionSuccess,
  GET_PUPILS_DYNAMIC_QUESTIONS_RESULTS_SUCCEEDED:
    onGetPupilsPracticeResultsSuccess,
  REVISION_UPDATED: onRevisionUpdated,
};

export const userSocketHandlers = {
  DIAGNOSTIC_PROGRESS_RESET_SUCCESS: onDiagnosticProgressReset,
  GET_PUPILS_PROGRESS_SUCCESS: onGetPupilsProgressSuccessUser,
  PROGRESS_UPDATED: onProgressUpdated,
};

import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Socket from 'services/socket';
import useStore from 'store';
import useUserStore from 'store/user';
import useAuthStore from 'store/auth';
import usePreviewCourse from 'helpers/hooks/use-preview-course';
import usePreviewChild from 'helpers/hooks/use-preview-child';
import usePreviewGoal from 'helpers/hooks/use-preview-goal';

const {
  setSocket,
  setIsLoading,
  setIsAppError,
  setIsConnecting,
  processOnConnectedData,
} = useStore.getState();
const { setSocketOnConnectData } = useUserStore.getState();

const requiredDataParam = (required) => [
  'requiredData',
  required ? 'all' : 'none',
];

function useSocketInit() {
  const location = useLocation();
  const isRevisionAssignment = location.pathname.startsWith('/assign-revision');
  const previewCourseId = usePreviewCourse() ?? null;
  const previewChildId = usePreviewChild() ?? null;
  const previewGoalId = usePreviewGoal() ?? null;
  const jwt = useAuthStore((state) => state.jwt);
  const requiresSocket = useAuthStore((state) => state.requiresSocket);
  const requiresData = useAuthStore((state) => state.requiresData);
  const setRequiresData = useAuthStore((state) => state.setRequiresData);
  const isLoading = useStore((state) => state.isLoading);
  const hasConnectionData = useStore((state) => state.hasConnectionData);
  const socketRef = useRef({});

  socketRef.current.initSocket = useCallback(() => {
    setIsConnecting(true);

    const url = new URL(process.env.REACT_APP_AWS_APIGATEWAY_SOCKET);
    const params = Object.fromEntries([
      ['token', jwt],
      requiredDataParam(requiresData),
    ]);
    if (previewCourseId) params.previewCourseId = previewCourseId;
    if (previewChildId) params.previewChildId = previewChildId;
    if (previewGoalId) params.previewGoalId = previewGoalId;
    if (isRevisionAssignment) params.isRevisionAssignment = true;
    url.search = new URLSearchParams(params).toString();

    // Don't set up a socket if we are just displaying the revision assignment page
    if (!isRevisionAssignment) {
      if (socketRef.current.socket) socketRef.current.socket.close();
      socketRef.current.socket = new Socket(url);

      const { socket } = socketRef.current;
      setSocket(socket);
      socket.addEventHandler('SOCKET_REOPEN_FAILED', () => setIsAppError(true));
      socket.addEventHandler('SOCKET_REOPENED', () => setIsAppError(false));
      socket.on('CONNECTED', (data = {}) => {
        processOnConnectedData(data);
        setSocketOnConnectData(data);
        setIsLoading(false);
        setIsConnecting(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [
    jwt,
    previewChildId,
    previewCourseId,
    previewGoalId,
    isRevisionAssignment,
    requiresData,
  ]);

  useEffect(() => {
    if (jwt) {
      const { initSocket, socket } = socketRef.current;
      if (requiresSocket) initSocket();
      else socket?.url.searchParams.set('token', jwt);
    }
  }, [jwt, requiresSocket]);

  // If we don't have connection data yet, make sure connection data is sent
  // from the backend on connection.
  useEffect(() => {
    const { socket } = socketRef.current;
    socket?.url.searchParams.set(...requiredDataParam(!hasConnectionData));
    setRequiresData(!hasConnectionData);
  }, [hasConnectionData, setRequiresData]);

  useEffect(() => {
    return () => {
      // Close socket if necessary on component unmount
      const { socket } = socketRef.current; // eslint-disable-line react-hooks/exhaustive-deps
      socket?.close();
      setSocket(null);
    };
  }, []);

  const { initSocket } = socketRef.current;
  return { isLoading, initSocket };
}

export default useSocketInit;

import React, { lazy, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import useStore from 'store';
import useAuthStore from 'store/auth';
import useUserStore from 'store/user';
import { setGlobalValue } from 'helpers/global-values';
// Don't lazy load ErrorRoutes to ensure they work when internet connection down
import ErrorRoutes from './error-routes';

if (process.env.REACT_APP_ENV === 'development') {
  setGlobalValue('store', useStore.getState());
  setGlobalValue('authStore', useAuthStore.getState());
  setGlobalValue('userStore', useUserStore.getState());
}

const PupilRoutes = lazy(() => import('./pupil-routes'));
const ParentRoutes = lazy(() => import('./parent-routes'));
const TeacherRoutes = lazy(() => import('./teacher-routes'));
const NoSchoolRoutes = lazy(() => import('./no-school-routes'));
const AssignRevisionRoutes = lazy(() => import('./assign-revision-routes'));

const authSelector = (state) => [
  state.isPupil,
  state.isParent,
  state.isTeacher,
  state.hasSchool,
];
const selector = (state) => [
  !!state.previewCourseId,
  !!state.previewChildId,
  !!state.previewGoalId,
  state.isJoiningInstitution,
  !!state.familyId,
  state.isAppError,
];

function AppRouter() {
  const location = useLocation();
  const [isPupil, isParent, isTeacher, hasSchool] = useAuthStore(
    authSelector,
    shallow
  );
  const [
    inPreviewMode,
    inChildPreviewMode,
    inGoalPreviewMode,
    isJoiningInstitution,
    hasFamilyId,
    isAppError,
  ] = useStore(selector, shallow);

  // UserType/jwt is updated when invitation is accepted, so needs to be memoized
  // to prevent different invitation component being rendered in <NoSchoolRoutes /> when
  // user is a guest, which navs user away from success page.
  const userType = useMemo(() => {
    return (isParent() && 'parent') || (isTeacher() && 'teacher') || 'guest';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAppError) {
    return <ErrorRoutes />;
  }
  if (location.pathname.startsWith('/assign-revision')) {
    return <AssignRevisionRoutes />;
  }
  // The pupil app is shown for pupils and
  // for teachers that are in course preview mode or
  // for parents that are previewing a child.
  if (isPupil() || inPreviewMode || inChildPreviewMode || inGoalPreviewMode) {
    return <PupilRoutes isRealPupil={isPupil()} />;
  }
  if ((!hasSchool() && !hasFamilyId) || isJoiningInstitution) {
    return <NoSchoolRoutes userType={userType} />;
  }
  if (isParent()) {
    return <ParentRoutes />;
  }
  if (isTeacher()) {
    return <TeacherRoutes />;
  }

  return null; // user type not supported
}

export default AppRouter;

/* eslint-disable no-param-reassign */
const getInitialState = () => ({
  revision: null,
  myRevision: [],
  previewRevisionId: undefined,
});

const revisionSlice = (set, get) => ({
  sliceName: 'revision',
  getInitialState,
  onConnected: (data) => {
    const { myRevision } = data;
    const { setMyRevision } = get();
    if (myRevision) {
      setMyRevision(myRevision);
    }
  },
  selectors: {
    ...getInitialState(),

    getRevision: (revisionId) =>
      get().myRevision.find((rev) => rev.id === revisionId),
  },
  mutators: {
    setMyRevision: (myRevision) =>
      set((draft) => {
        draft.myRevision = myRevision;
      }),
    setRevisionGoals: (revisionId, goals) =>
      set((draft) => {
        draft.myRevision = draft.myRevision.map((revision) => {
          if (revision.id === revisionId) {
            return { ...revision, goals };
          }
          return revision;
        });
      }),
    addRevisionAssignment: (revision) =>
      set((draft) => {
        draft.myRevision = [
          ...draft.myRevision.filter((rev) => rev.id !== revision.id),
          revision,
        ].filter((rev) => !rev.isUnassigned);
      }),
    getNextAndPrevGoalInRevision: (goalId, revisionId) => {
      if (!revisionId) return {};
      const { getRevision } = get();
      const { revisionGoals, goals } = getRevision(revisionId) ?? {};

      const currGoalIx = revisionGoals.findIndex(
        (goal) => goal.goalId === goalId
      );

      let ideaIdOfNextGoal;
      let ideaIdOfPrevGoal;

      const nextGoalIdInRevision = revisionGoals[currGoalIx + 1]?.goalId;
      if (nextGoalIdInRevision)
        ideaIdOfNextGoal = goals.find(
          (goal) => goal.id === nextGoalIdInRevision
        ).ideaId;

      const prevGoalIdInRevision = revisionGoals[currGoalIx - 1]?.goalId;
      if (prevGoalIdInRevision)
        ideaIdOfPrevGoal = goals.find(
          (goal) => goal.id === prevGoalIdInRevision
        ).ideaId;

      return {
        nextGoalIdInRevision,
        ideaIdOfNextGoal,
        prevGoalIdInRevision,
        ideaIdOfPrevGoal,
      };
    },
  },
});

export default revisionSlice;
